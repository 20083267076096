import React, { Component } from "react";
import moment from "moment";
import "moment/locale/fr";
import history from "../../../history";
import localApi from "../../apis/localApi";
import { toast } from "react-toastify";
import Can from "../auth/Can";
moment.locale("fr");

export default class EntretienShowLine extends Component {
    handleClick = () => {
        history.push(
            `/dossiers/${this.props.dossId}/entretiens/${this.props.folder}/${this.props._id}`
        ); 
    };

    handleDelete = async e => {
        e.stopPropagation();
        try {
            await localApi.delete(
                `/dossiers/${this.props.dossId}/${this.props.folder}/${this.props._id}/ent`
            );
            toast.success("Suppression réussie");
            this.props.fetchDocuments();
        } catch (error) {
            toast.error(error.response.data.error?.message);
        }
    };

    render() {
        let { titre, date } = this.props;
        return (
            <tr onClick={this.handleClick} role="button">
                <td className={"font-weight-bold"}>
                    {titre}
                </td>
                <td>{moment(date).format("Do MMMM YYYY")}</td>
                <td className="py-0">
                    <Can perform="entretien:delete">
                        <i
                            className="fas fa-trash p-2"
                            onClick={this.handleDelete}
                        ></i>
                    </Can>
                </td>
            </tr>
        );
    }
}
