import { FETCH_CONVERSATIONS } from "../actions/types";

const defaultState = { conversations: [], infos: { totalUnread: 0 } };

export default function (state = defaultState, action) {
    switch (action.type) {
        case FETCH_CONVERSATIONS:
            return action.payload || false;
        default:
            return state;
    }
}
