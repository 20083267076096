import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import history from "../../../history";
import { getFullName } from "../../helpers/clientHelpers";

class LogButton extends Component {
    render() {
        if (this.props.user !== null) {
            if (this.props.user !== false) {
                return (
                    <li className="nav-item  nav-profile border-0 pl-4">
                        <Dropdown alignRight>
                            <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                                <i className="fas fa-user"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="navbar-dropdown preview-list border">
                                <Dropdown.Item
                                    className="dropdown-item  d-flex align-items-center"
                                    role="button"
                                    onClick={() => {
                                        history.push("/messages");
                                    }}
                                >
                                    <p className="my-2 mx-auto font-weight-medium">
                                        {getFullName(this.props.user)}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="dropdown-item preview-item d-flex align-items-center border-0 py-3"
                                    onClick={evt => {
                                        evt.preventDefault();
                                        history.push("/profil");
                                    }}
                                >
                                    Mon profil
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="dropdown-item preview-item d-flex align-items-center border-0 py-3"
                                    href="/api/auth/logout"
                                >
                                    Déconnexion
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                );
            } else {
                return (
                    <a href="/auth/login" className="btn btn-light">
                        Connexion
                    </a>
                );
            }
        }
        return null;
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth,
    };
};

export default connect(mapStateToProps)(LogButton);
