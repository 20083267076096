import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import history from "../../../history";
import { connect } from "react-redux";

import Can from "../auth/Can";
import UserSidebar from "../auth/UserSidebar";

class Sidebar extends Component {
    state = {};

    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        document.querySelector("#sidebar").classList.remove("active");
        Object.keys(this.state).forEach(i => {
            this.setState({ [i]: false });
        });

        const dropdownPaths = [
            { path: "/utilisateurs", state: "userPagesMenuOpen" },
            { path: "/tenants", state: "tenantPagesMenuOpen" },
        ];

        dropdownPaths.forEach(obj => {
            if (this.isPathActive(obj.path)) {
                this.setState({ [obj.state]: true });
            }
        });
    }
    render() {
        const tenantLogo = this.props.user?.tenant?.logo;
        let logoDisplayUrl;
        if(tenantLogo) {
            logoDisplayUrl = `/api/tenants/logo/${tenantLogo}`;
        } else {
            logoDisplayUrl = require("../../../assets/images/logo-mini-transp.svg");
        }
        return (
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
                    <button
                        className="sidebar-brand brand-logo btn"
                        onClick={() => history.push("/dashboard")}
                    >
                        <img
                            src={logoDisplayUrl}
                            alt="logo"
                        />
                    </button>
                    <a className="sidebar-brand brand-logo-mini pt-3" href="/">
                        <img
                            src={logoDisplayUrl}
                            alt="logo"
                        />
                    </a>
                </div>
                <ul className="nav">
                    <UserSidebar />
                    <Can perform="showClientDashboard">
                        <li
                            className={
                                this.isPathActive("/dashboard")
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link className="nav-link" to="/dashboard">
                                <i className="fas fa-desktop menu-icon"></i>
                                <span className="menu-title">
                                    Vue d'ensemble
                                </span>
                            </Link>
                        </li>
                    </Can>
                    <Can perform="show:clientFolders">
                        {this.props.user.currentDossier && (
                            <li
                                className={
                                    this.isPathActive(
                                        `/dossiers/${this.props.user.currentDossier?._id}`
                                    ) && 0
                                        ? "nav-item active"
                                        : "nav-item"
                                }
                            >
                                <Link
                                    className="nav-link"
                                    to={`/dossiers/${this.props.user.currentDossier?._id}`}
                                >
                                    <i className="fas fa-info-circle menu-icon"></i>
                                    <span className="menu-title">
                                        Infos projet
                                    </span>
                                </Link>
                            </li>
                        )}
                        {this.props.user.currentDossier && (
                            <li
                                className={
                                    this.isPathActive(
                                        `/dossiers/${this.props.user.currentDossier?._id}/documents`
                                    )
                                        ? "nav-item active"
                                        : "nav-item"
                                }
                            >
                                <Link
                                    className="nav-link"
                                    to={`/dossiers/${this.props.user.currentDossier?._id}/documents`}
                                >
                                    <i className="fas fa-file-alt menu-icon"></i>
                                    <span className="menu-title">
                                        Documents
                                    </span>
                                </Link>
                            </li>
                        )}
                        {this.props.user.currentDossier && (
                            <li
                                className={
                                    this.isPathActive(
                                        `/dossiers/${this.props.user.currentDossier?._id}/messages`
                                    )
                                        ? "nav-item active"
                                        : "nav-item"
                                }
                            >
                                <Link
                                    className="nav-link"
                                    to={`/dossiers/${this.props.user.currentDossier?._id}/messages`}
                                >
                                    <i className="fas fa-envelope menu-icon"></i>
                                    <span className="menu-title">
                                        Discussion
                                    </span>
                                </Link>
                            </li>
                        )}
                        <li
                            className={
                                this.isPathActive("/profil")
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link className="nav-link" to={"/profil"}>
                                <i className="fas fa-user-circle menu-icon"></i>
                                <span className="menu-title">Mon profil</span>
                            </Link>
                        </li>
                        {this.props.user.dossiers.length > 1 && (
                            <li
                                className={
                                    this.isPathActive("/changementDossier")
                                        ? "nav-item active"
                                        : "nav-item"
                                }
                            >
                                <Link
                                    className="nav-link"
                                    to={"/changementDossier"}
                                >
                                    <i className="fas fa-exchange-alt menu-icon"></i>
                                    <span className="menu-title">
                                        Changer de dossier
                                    </span>
                                </Link>
                            </li>
                        )}
                    </Can>

                    <Can perform="showAdminDashboard">
                        <li
                            className={
                                this.isPathActive("/dossiers")
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link className="nav-link" to="/dossiers">
                                <i className="fas fa-file-alt menu-icon"></i>
                                <span className="menu-title">Dossiers</span>
                            </Link>
                        </li>
                    </Can>
                    <Can perform="showAdminDashboard">
                        <li
                            className={
                                this.isPathActive("/profil")
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link className="nav-link" to="/profil">
                                <i className="fas fa-user-circle menu-icon"></i>
                                <span className="menu-title">Mon profil</span>
                            </Link>
                        </li>
                    </Can>

                    <Can perform="tenant:manage">
                        <li
                            className={
                                this.isPathActive("/tenants")
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <div
                                className={
                                    this.state.tenantPagesMenuOpen
                                        ? "nav-link menu-expanded"
                                        : "nav-link"
                                }
                                onClick={() =>
                                    this.toggleMenuState("tenantPagesMenuOpen")
                                }
                                data-toggle="collapse"
                            >
                                <i className="fas fa-users menu-icon"></i>
                                <span className="menu-title">Entreprises</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.tenantPagesMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <Can perform="tenant:manage">
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    this.isPathActive(
                                                        "/tenants/gestionTenants"
                                                    )
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to="/tenants/gestionTenants"
                                            >
                                                Gestion entreprise
                                            </Link>
                                        </li>
                                    </Can>
                                    <Can perform="tenant:add">
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    this.isPathActive(
                                                        "/tenants/ajoutTenant"
                                                    )
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to="/tenants/ajoutTenant"
                                            >
                                                Ajout entreprise
                                            </Link>
                                        </li>
                                    </Can>
                                </ul>
                            </Collapse>
                        </li>
                    </Can>


                    <Can perform="client:manage">
                        <li
                            className={
                                this.isPathActive("/utilisateurs")
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <div
                                className={
                                    this.state.userPagesMenuOpen
                                        ? "nav-link menu-expanded"
                                        : "nav-link"
                                }
                                onClick={() =>
                                    this.toggleMenuState("userPagesMenuOpen")
                                }
                                data-toggle="collapse"
                            >
                                <i className="fas fa-users menu-icon"></i>
                                <span className="menu-title">Utilisateurs</span>
                                <i className="menu-arrow"></i>
                            </div>

                            <Collapse in={this.state.userPagesMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <Can perform="client:manage">
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    this.isPathActive(
                                                        "/utilisateurs/clients"
                                                    )
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to="/utilisateurs/clients"
                                            >
                                                Gérer les clients
                                            </Link>
                                        </li>
                                    </Can>
                                    <Can perform="client:add">
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    this.isPathActive(
                                                        "/utilisateurs/ajoutClient"
                                                    )
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to="/utilisateurs/ajoutClient"
                                            >
                                                Ajout client
                                            </Link>
                                        </li>
                                    </Can>
                                    <Can perform="admin:manage">
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    this.isPathActive(
                                                        "/utilisateurs/admins"
                                                    )
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to="/utilisateurs/admins"
                                            >
                                                Gérer les admins
                                            </Link>
                                        </li>
                                    </Can>
                                    <Can perform="admin:add">
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    this.isPathActive(
                                                        "/utilisateurs/ajoutAdmin"
                                                    )
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to="/utilisateurs/ajoutAdmin"
                                            >
                                                Ajout admin
                                            </Link>
                                        </li>
                                    </Can>
                                </ul>
                            </Collapse>
                        </li>
                    </Can>
                </ul>
            </nav>
        );
    }

    isPathActive = path => {
        return this.props.location.pathname.startsWith(path);
    };

    componentDidMount() {
        this.onRouteChanged();
        // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector("body");
        document.querySelectorAll(".sidebar .nav-item").forEach(el => {
            el.addEventListener("mouseover", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.add("hover-open");
                }
            });
            el.addEventListener("mouseout", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.remove("hover-open");
                }
            });
        });
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth,
    };
};

export default connect(mapStateToProps)(withRouter(Sidebar));
