export function getFullName(client) {
    let name = `${client.profil1.prenom} ${client.profil1.nom}`;
    if (client.profil2) {
        name += ` et ${client.profil2.prenom} ${client.profil2.nom}`;
    }
    return name;
}

export function clientFormToObject(c) {
    const newClient = {
        adresse: {
            rue: c.rue,
            ville: c.ville,
            codepostal: c.codepostal,
        },
        profil1: {
            designation: c.designation,
            prenom: c.prenom,
            nom: c.nom,
            profession: c.profession,
            societe: c.societe,
            naissance: c.naissance,
            lieunaissance: c.lieunaissance,
        },
        profil2: c.toggleDouble
            ? {
                  designation: c.designation2,
                  prenom: c.prenom2,
                  nom: c.nom2,
                  profession: c.profession2,
                  societe: c.societe2,
                  naissance: c.naissance2,
                  lieunaissance: c.lieunaissance2,
              }
            : undefined,
        email: c.email,
        enfants: c.enfants,
        infos: c.infos,
        telephone: c.telephone,
    };

    return newClient;
}

export function clientObjectToForm(c) {
    try {
        let clientForm = {
            designation: c.profil1.designation,
            prenom: c.profil1.prenom,
            nom: c.profil1.nom,
            naissance: c.profil1.naissance,
            lieunaissance: c.profil1.lieunaissance,
            profession: c.profil1.profession,
            societe: c.profil1.societe,

            telephone: c.telephone,
            email: c.email,
            rue: c.adresse.rue,
            ville: c.adresse.ville,
            codepostal: c.adresse.codepostal,
            enfants: c.enfants,
            infos: c.infos,
        };

        if (c.profil2) {
            clientForm = {
                ...clientForm,
                designation2: c.profil2.designation,
                prenom2: c.profil2.prenom,
                nom2: c.profil2.nom,
                naissance2: c.profil2.naissance,
                lieunaissance2: c.profil2.lieunaissance,
                profession2: c.profil2.profession,
                societe2: c.profil2.societe,
                toggleDouble: true,
            };
        }

        return clientForm;
    } catch (error) {
        console.error(error);
        return {};
    }
}
