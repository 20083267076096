import React from "react";
import { Route, Redirect } from "react-router-dom";
import rules from "./rbac-rules";
import { connect } from "react-redux";

const check = (rules, role, action, data) => {
    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false;
        }

        return permissionCondition(data);
    }
    return false;
};

class CanRoute extends React.Component {
    render() {
        const { component: Component, perform, user, ...props } = this.props;
        let adjustedRules = rules
        if (user && user.role === "superadmin" && user.isTenantManager) {
            adjustedRules.superadmin.static = adjustedRules.superadmin.static.concat([
                "tenant:manage",
                "tenant:add",
            ]);
        }
        return (
            <Route
                {...props}
                render={props => {
                    if (this.props.user === null) {
                        return null;
                    } else if (this.props.user === false) {
                        return <Redirect to="/auth/login" />;
                    } else if (
                        !perform ||
                        check(
                            adjustedRules,
                            user.role,
                            perform,
                            user
                        )
                    ) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect to="/dashboard" />;
                    }
                }}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth,
    };
};

export default connect(mapStateToProps)(CanRoute);
