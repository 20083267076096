import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser } from "./actions/AuthActions";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./App.scss";

import AppRoutes from "./AppRoutes";
import Navbar from "./components/shared/Navbar";
import Sidebar from "./components/shared/Sidebar";

class App extends Component {
    state = {};
    componentDidMount() {
        this.onRouteChanged();
        this.props.fetchUser();
    }
    render() {
        let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
        let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : "";
        return (
            <div className="container-scroller">
                {this.props.user && navbarComponent}
                <div className="container-fluid page-body-wrapper">
                    {this.props.user && sidebarComponent}
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <AppRoutes user={this.props.user} />
                            <ToastContainer
                                position="bottom-right"
                                autoClose={3000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                pauseOnFocusLoss={false}
                                rtl={false}
                                draggable
                                pauseOnHover
                                transition={Slide}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        window.scrollTo(0, 0);
        const fullPageLayoutRoutes = ["/auth/login", "/auth/reset"];
        for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
            if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
                this.setState({
                    isFullPageLayout: true,
                });
                document
                    .querySelector(".page-body-wrapper")
                    .classList.add("full-page-wrapper");
                break;
            } else {
                this.setState({
                    isFullPageLayout: false,
                });
                document
                    .querySelector(".page-body-wrapper")
                    .classList.remove("full-page-wrapper");
            }
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth,
    };
};

export default connect(mapStateToProps, { fetchUser })(withRouter(App));
