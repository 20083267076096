import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";
import localApi from "../../apis/localApi";

class DiskUsage extends Component {
    state = { data: null };
    componentDidMount = async () => {
        try {
            let data = await localApi.get("/diskUsage");
            this.setState({ data: { ...data.data } });
        } catch (error) {}
    };
    render() {
        if (!this.state.data) return null;
        const { ratio, size, free } = this.state.data;
        let variant = "success";
        if (ratio >= 70 && ratio < 90) {
            variant = "warning";
        } else if (ratio >= 90) {
            variant = "danger";
        }
        return (
            <div className="mt-3 border border-secondary p-1 rounded">
                <p className="mb-2">Disque :</p>
                <ProgressBar
                    style={{ height: "15px" }}
                    striped
                    variant={variant}
                    now={Number(ratio)}
                    label={ratio >= 20 ? `${ratio}%` : ""}
                />
                <p className="mt-2 mb-0">
                    {free} libre / {size}
                </p>
            </div>
        );
    }
}

export default DiskUsage;
