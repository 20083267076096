import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import AuthReducer from "./AuthReducer";
import ConvReducer from "./ConvReducer";

export default combineReducers({
    auth: AuthReducer,
    convs: ConvReducer,
    form: formReducer,
});
