const commonAdmins = [
    "user:show",
    "user:resendActivation",
    "client:add",
    "client:edit",
    "client:manage",
    "admin:manage",
    "dossier:list",
    "dossier:add",
    "dossier:edit",
    "dossier:deleteUser",
    "dossier:addUsers",
    "dossier:addDocument",
    "document:addSigners",
    "dossier:addDocument",
    "dossier:changePicture",
    "document:notifyAdd",
    "document:delete",
    "entretien:notifyAdd",
    "diskusage:see",
    "showAdminDashboard",
    "entretien:delete"
];

const rules = {
    client: {
        static: [
            "showClientDashboard",
            "show:clientFolders",
            "dossier:listOwn",
            "sendMessage",
        ],
    },
    admin: {
        static: commonAdmins.concat(["sendMessage"]),
    },
    superadmin: {
        static: commonAdmins.concat([
            "user:editSelf",
            "client:delete",
            "admin:add",
            "admin:edit",
            "admin:delete",
            "dossier:delete",
            "sendMessage",
        ]),
    },
};

export default rules;
