import React from "react";

const RenderRole = props => {
    switch (props.role) {
        case "client":
            return (
                <span
                    className={
                        "badge badge-secondary text-reset m-0 " +
                        props.className
                    }
                >
                    Client
                </span>
            );
        case "prestataire":
            return (
                <span className={"badge badge-success m-0 " + props.className}>
                    Prestataire
                </span>
            );
        case "admin":
            return (
                <span className={"badge badge-primary m-0 " + props.className}>
                    Admin
                </span>
            );
        case "superadmin":
            return (
                <span className={"badge badge-info m-0 " + props.className}>
                    Super Admin
                </span>
            );
        default:
            return null;
    }
};

export default RenderRole;
