import React from "react";
import _ from "lodash";

export const typeToText = role => {
    switch (role) {
        case "signdoc":
            return "Un document est prêt à être signé";
        case "newdoc":
            return "Nouveaux documents";
        case "newmessage":
            return "Nouveau message";
        default:
            return "";
    }
};

export const typeToIcon = role => {
    switch (role) {
        case "signdoc":
            return <i className="fas fa-file-signature m-auto"></i>;
        case "newdoc":
            return <i className="fas fa-file-upload m-auto"></i>;
        case "newmessage":
            return <i className="fas fa-envelope-open-text m-auto"></i>;
        default:
            return "";
    }
};

export const typeToPreview = ({ notifType, content }) => {
    switch (notifType) {
        case "signdoc":
            return (
                <p className="font-weight-light small-text mb-1 font-italic">
                    {_.truncate(content.document, {
                        omission: " [...]",
                    })}
                </p>
            );
        case "newdoc":
            return (
                <p className="font-weight-light small-text mb-1 font-italic">
                    {_.truncate(content.folder, {
                        omission: " [...]",
                    })}
                </p>
            );
        case "newmessage":
            return (
                <p className="font-weight-light small-text mb-1 font-italic">
                    {_.truncate(content.messageContent, {
                        omission: " [...]",
                    })}
                </p>
            );
        default:
            return "";
    }
};
