import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/components/shared/Spinner";
import CanRoute from "./CanRoute";
import EntretienShowLine from "./components/entretiens/EntretienShowLine";

const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));

const Dossiers = lazy(() => import("./components/dossiers/Dossiers"));
const ChangeCurrentDoss = lazy(() =>
    import("./components/utilisateurs/ChangeCurrentDoss")
);
const AjoutTenant = lazy(() => import("./components/tenants/AjoutTenant"));
const GestionTenant = lazy(() => import("./components/tenants/GestionTenants"));
const AjoutDossier = lazy(() => import("./components/dossiers/AjoutDossier"));
const ShowDossier = lazy(() => import("./components/dossiers/ShowDossier"));
const ChangePicture = lazy(() => import("./components/dossiers/ChangePicture"));
const Documents = lazy(() => import("./components/documents/Documents"));
const Folders = lazy(() => import("./components/documents/Folders"));
const ShowDocument = lazy(() => import("./components/documents/ShowDocument"));
const ShowEntretien = lazy(() => import("./components/entretiens/ShowEntretien"));
const AjoutSignDocument = lazy(() =>
    import("./components/documents/AjoutSignDocument"));
const AjoutEntretien = lazy(() =>
import("./components/documents/AjoutEntretien"));

const ShowMessages = lazy(() => import("./components/messagerie/ShowMessages"));

const AjoutClient = lazy(() => import("./components/utilisateurs/AjoutClient"));
const GestionClients = lazy(() =>
    import("./components/utilisateurs/GestionClients")
);
const AjoutAdmin = lazy(() => import("./components/utilisateurs/AjoutAdmin"));
const GestionAdmins = lazy(() =>
    import("./components/utilisateurs/GestionAdmins")
);
const ShowUser = lazy(() => import("./components/utilisateurs/ShowUser"));
const Profil = lazy(() => import("./components/utilisateurs/Profil"));
const ChangePassword = lazy(() =>
    import("./components/utilisateurs/ChangePassword")
);

const Login = lazy(() => import("./components/auth/Login"));
const Reset = lazy(() => import("./components/auth/Reset"));

class AppRoutes extends Component {
    render() {
        return (
            <Suspense fallback={<Spinner />}>
                <Switch>
                    <Route path="/auth/login" exact component={Login} />
                    <Route path="/auth/reset" exact component={Reset} />

                    <CanRoute exact path="/dashboard" component={Dashboard} />

                    <CanRoute exact path="/profil" component={Profil} />
                    <CanRoute
                        exact
                        path="/profil/changePassword"
                        component={ChangePassword}
                    />

                    <CanRoute
                        perform="dossier:list"
                        path="/dossiers"
                        exact
                        component={Dossiers}
                    />

                    <CanRoute
                        perform="dossier:listOwn"
                        path="/changementDossier"
                        exact
                        component={ChangeCurrentDoss}
                    />

                    <CanRoute
                        perform="dossier:add"
                        path="/dossiers/ajout/:id"
                        exact
                        component={AjoutDossier}
                    />

                    <CanRoute
                        path="/dossiers/:id/documents"
                        component={Folders}
                        exact
                    />
                    <CanRoute
                        path="/dossiers/:id/documents/:folder"
                        component={Documents}
                        exact
                    />

                    <CanRoute
                        path="/dossiers/:dossId/documents/:folder/:docId"
                        exact
                        component={ShowDocument}
                    />

                    <CanRoute
                        path="/dossiers/:dossId/entretiens/:folder/:docId"
                        exact
                        component={ShowEntretien}
                    />

                    <CanRoute
                        perform="document:addSigners"
                        path="/dossiers/:id/:folder/ajoutSignDocument"
                        component={AjoutSignDocument}
                        exact
                    />

                    <CanRoute
                        path="/dossiers/:id/:folder/ajoutEntretien"
                        component={AjoutEntretien}
                        exact
                    />

                    <CanRoute
                        path="/dossiers/:id"
                        component={ShowDossier}
                        exact
                    />
                    <CanRoute
                        perform="dossier:changePicture"
                        path="/dossiers/:id/changePicture"
                        component={ChangePicture}
                        exact
                    />
                    <CanRoute
                        path="/dossiers/:id/messages"
                        component={ShowMessages}
                        exact
                    />
                    <CanRoute
                        path="entretien:delete"
                        component={EntretienShowLine}
                        exact
                    />

                    <CanRoute
                        perform="client:add"
                        path="/utilisateurs/ajoutClient"
                        component={AjoutClient}
                    />
                    <CanRoute
                        perform="client:manage"
                        path="/utilisateurs/clients"
                        component={GestionClients}
                    />
                    <CanRoute
                        perform="admin:add"
                        path="/utilisateurs/ajoutAdmin"
                        component={AjoutAdmin}
                    />
                    <CanRoute
                        perform="tenant:add"
                        path="/tenants/ajoutTenant"
                        component={AjoutTenant}
                    />
                    <CanRoute
                        perform="tenant:manage"
                        path="/tenants/gestionTenants"
                        component={GestionTenant}
                    />

                    <CanRoute
                        perform="admin:manage"
                        path="/utilisateurs/admins"
                        component={GestionAdmins}
                    />
                    <CanRoute
                        perform="user:show"
                        path="/utilisateurs/:id"
                        component={ShowUser}
                    />

                    <Redirect to="/dashboard" />
                </Switch>
            </Suspense>
        );
    }
}

export default AppRoutes;
