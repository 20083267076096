import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import history from "../../../history";
import localApi from "../../apis/localApi";
import moment from "moment";
import "moment/locale/fr";
import {
    typeToText,
    typeToIcon,
    typeToPreview,
} from "../../helpers/notificationTypeConverter";
import { toast } from "react-toastify";
import { fetchUser } from "../../actions/AuthActions";
moment.locale("fr");

class NotificationDropdown extends React.Component {
    state = { notifications: [] };

    changeDoss = async (dossId) => {
        if (dossId !== this.props.user.currentDossier._id) {
            try {
                await localApi.post("/users/changeCurrentDoss", {
                    currentDoss: dossId,
                });
                toast.success("Le dossier courant a été changé");
                this.props.fetchUser();
            } catch (error) {
                toast.error("Impossible de changer de dossier");
            }
        }
    };

    handleClick = (notif) => async () => {
        try {
            localApi
                .get("/notifications/" + notif._id + "/read")
                .then((notifs) => {
                    this.setState({ notifications: notifs.data.notifications });
                });
        } catch (error) {
            console.error(error);
        }
        switch (notif.notifType) {
            case "newdoc":
                history.push(
                    `/dossiers/${notif.content.dossierId}/documents/${notif.content.folderRef}`
                );

                break;
            case "signdoc":
                history.push(
                    `/dossiers/${notif.content.dossierId}/documents/${notif.content.folder}/${notif.content.documentId}`
                );

                break;
            case "newmessage":
                history.push(`/dossiers/${notif.content.dossierId}/messages`);

                break;
            default:
                break;
        }
        if (this.props.user.role === "client")
            this.changeDoss(notif.content.dossierId);
    };

    handleDelete = (notifId) => async (e) => {
        e.stopPropagation();
        try {
            let notifs = await localApi.delete("/notifications/" + notifId);
            this.setState({ notifications: notifs.data.notifications });
        } catch (error) {}
    };

    getNotifs = async () => {
        try {
            let notifs = await localApi.get("/notifications");
            this.setState({ notifications: notifs.data });
        } catch (error) {}
    };

    componentDidMount = async () => {
        this.getNotifs();
        setInterval(() => {
            this.getNotifs();
        }, 10000);
    };

    renderNotifications = (notifs) => {
        return notifs.map((notif) => (
            <div key={notif._id}>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                    className={
                        "dropdown-item preview-item d-flex align-items-center " +
                        (notif.read ? "" : "unread")
                    }
                    onClick={this.handleClick(notif)}
                >
                    <div className="preview-thumbnail">
                        {typeToIcon(notif.notifType)}
                    </div>
                    <div className="preview-item-content py-1 mr-3">
                        <h6 className="preview-subject text-dark mb-1">
                            {typeToText(notif.notifType)}
                        </h6>
                        {typeToPreview(notif)}
                        <p className="font-weight-light small-text mb-0">
                            {moment(notif.date).fromNow()}
                        </p>
                    </div>
                    <button
                        className="btn ml-auto p-0 text-danger"
                        onClick={this.handleDelete(notif._id)}
                    >
                        <i className="fas fa-trash-alt mr-0"></i>
                    </button>
                </Dropdown.Item>
            </div>
        ));
    };
    render() {
        const notifications = this.state.notifications;
        let nbNew = notifications.filter((n) => !n.read).length;
        return (
            <li className="nav-item  nav-profile border-0 pl-4">
                <Dropdown alignRight>
                    <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                        <i className="fas fa-bell"></i>
                        {nbNew > 0 && (
                            <span className="count bg-success">{nbNew}</span>
                        )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="navbar-dropdown preview-list">
                        <Dropdown.Item
                            className="dropdown-item py-3 d-flex align-items-center"
                            href="!#"
                            onClick={(evt) => evt.preventDefault()}
                        >
                            <p className="mb-0 font-weight-medium float-left">
                                {nbNew > 0 ? nbNew : "Aucune"} nouvelle
                                {nbNew > 1 && "s"} notification
                                {nbNew > 1 && "s"}
                            </p>
                            {/* <span className="badge badge-pill badge-primary float-right">
                                Voir tout
                            </span> */}
                        </Dropdown.Item>
                        <div className="preview-container">
                            {this.renderNotifications(notifications)}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </li>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth,
    };
};

export default connect(mapStateToProps, { fetchUser })(NotificationDropdown);
