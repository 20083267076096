import { Component } from 'react';
import rules from "../../rbac-rules";
import { connect } from "react-redux";

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

class Can extends Component {
  render() {
      if(this.props.user && check(rules, this.props.user.role, this.props.perform, this.props.user)) {
        return this.props.children;
      }
      return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth
  }
}

export default connect(mapStateToProps)(Can);
